/**
 * Video Galleries
**/

// import { Luminous } from 'luminous-lightbox';
var Luminous = require('luminous-lightbox').Luminous;
var LuminousGallery = require('luminous-lightbox').LuminousGallery;

function videoGalleries () {
    const access = ( document.querySelector('.--no-access') ) ? false : true;
    const galleryPictures = document.querySelectorAll('.gallery-picture');

    if (access && galleryPictures) {
        new LuminousGallery(galleryPictures);
    }
};

export { videoGalleries };
