/**
 * Marquee
**/


function marquee (containerElement, contentCopies, speed) {

    // Check if element exists in the current page
    if (document.querySelector(containerElement)) {
        const container = document.querySelector(containerElement);
        const content = container.children[0];
        let contentWidth = content.clientWidth;
        let margin = 0;

        // Duplicate contents to create the endless effect
        for (let i = 0; i < contentCopies; i++) {
            let elementClone = content.cloneNode(true);
            container.appendChild(elementClone);
        }

        // Moving the contents
        function movingContents () {
            content.style.marginLeft = `-${margin}px`

            if (margin > contentWidth) {
                margin = 0;
            }
            margin++;
        }

        // The interval
        let timeInterval = setInterval(movingContents, speed);
    }
};

export { marquee };
