/**
 * Hero related functionalities
**/


function hero () {

    const cover = document.querySelector('.v-home__hero-cover');

    if (cover) {
        setTimeout( () => {
            cover.classList.add('v-home__hero-cover--is-hidden');
        }, 3000);
    };

};

export { hero };
