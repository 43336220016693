//
// Swiper.js Instances
// ================================================================================


import Swiper, { Navigation, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';


function sliders() {

    // Models Cards Slider
    // ==================================================
    if (document.querySelector('.v-home__models-list')) {

        const modelsList = document.querySelector('.v-home__models-list');
        const btnPrev = modelsList.querySelector('.c-slider__prev');
        const btnNext = modelsList.querySelector('.c-slider__next');

        const reportsSliders = new Swiper(modelsList, {
            modules: [ Navigation, A11y ],

            // Optional parameters
            loop: false,
            slidesPerView: 1.2,
            speed: 1000,
            spaceBetween: 16,
            slidesOffsetAfter: 16,
            slidesOffsetBefore: 16,

            // Navigation arrows
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext,
            },

            breakpoints: {
                560: {
                    slidesPerView: 2.4,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                    slidesOffsetAfter: 16,
                    slidesOffsetBefore: 16,
                },
                1024: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    slidesOffsetAfter: 0,
                    slidesOffsetBefore: 0,
                }
            }
        });
    };


    // Gallery
    // ==================================================
    if (document.querySelector('.v-video__gallery-pics')) {

        const gallery = document.querySelector('.v-video__gallery-pics');
        const btnPrev = gallery.querySelector('.c-slider__prev');
        const btnNext = gallery.querySelector('.c-slider__next');

        const gallerySlider = new Swiper(gallery, {
            modules: [ Navigation, A11y ],

            // Optional parameters
            loop: false,
            // slidesPerView: 1.2,
            slidesPerView: "auto",
            speed: 1000,
            spaceBetween: 16,
            slidesOffsetAfter: 16,
            slidesOffsetBefore: 16,

            // Navigation arrows
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext,
            }
        });
    };

}

export { sliders };
