//
// Entry point for JS
// ================================================================================

import { categories } from './categories.js';
import { countdown } from './countdown.js';
import { hero } from './hero.js';
import { marquee } from './marquee.js';
import { menu } from './menu.js';
import { menuTransparent } from './menuTransparent.js';
import { movingMenu } from './movingMenu';
import { sliders } from './sliders.js';
import { video } from './video.js';
import { videoCards } from './video-cards.js';
import { videoGalleries } from './video-galleries.js';

function autorun(){
    console.log('Scripts Loaded!');

    categories();
    countdown();
    hero();
    marquee('.c-marquee', 2, 12);
    menu();
    menuTransparent();
    movingMenu();
    sliders();
    video();
    videoCards();
    videoGalleries();

};

if (document.addEventListener) document.addEventListener("DOMContentLoaded", autorun, false);
else if (document.attachEvent) document.attachEvent("onreadystatechange", autorun);
else window.onload = autorun;
