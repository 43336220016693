
function movingMenu(){

    var prevScrollpos = window.pageYOffset;
    var firstNavbar = document.getElementById("firstNavbar");
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        var currentScrollPos = window.pageYOffset;
        
        if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {

            
           
            if(firstNavbar){ 
                document.getElementById("navbar").style.top = "0px";
                firstNavbar.style.display = "none";
            }
            
            
        } else {
            
            if(firstNavbar){ 
                document.getElementById("navbar").style.top = "-3.5em";
                firstNavbar.style.display = "flex";
            }
            
            
        }
        
        
        prevScrollpos = currentScrollPos;
    }


};

export { movingMenu };

