/**
 * Categories
**/


function categories () {

    if (document.querySelector('.v-videos__category-toggle')) {

        const toggle = document.querySelector('.v-videos__category-toggle');
        const categories = document.querySelector('.v-videos__categories');

        toggle.addEventListener('click', () => {

            if (categories.classList.contains('--mobile-hidden')) {
                categories.classList.remove('--mobile-hidden');
            } else {
                categories.classList.add('--mobile-hidden');
            }

        });

    }
};

export { categories };
