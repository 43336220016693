/**
 * Video related functionalities
**/


function video () {

    const access = document.querySelector('.--no-access');

    if (access) {
        const playButton = document.querySelector('.v-video__play');
        const modal = document.querySelector('.v-video__restricted');

        if (playButton) {
            playButton.addEventListener('click', () => {
                playButton.classList.add('--is-hidden');
                modal.classList.remove('--is-hidden');
            });
        }
    };

};

export { video };
