/**
 * Menu related functions
 * TO DO:
 * Expand a11y with https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
**/

function menuTransparent() {
    console.log('menu_transparent')
    const menu = document.querySelector('#firstNavbar');
    const toggle = document.querySelector('#transparentToggle');
    const links =  document.querySelector('#menu-links');
    const banner = document.querySelector('#aboveNav')

    function checkBreackpoint() {
        // If screensize is bigger than mobile, change a11y params
    };
    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    function preventDefault(e) {
    e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; } 
    }));
    } catch(e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
        window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }
  
  // call this to Enable
    function enableScroll() {
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
        window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', preventDefault, wheelOpt);
        window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    function menuToggle() {

        toggle.addEventListener('click', () => {
            console.log('click_transparent!');
            if(menu.style.top != '0'){
                
                if(banner !== null){
                    menu.style.top = '-1px';
                    banner.style.display = 'none';
                }else{
                    menu.style.background = '#120020';
                }
                
                disableScroll()
            }
            
            if(menu.classList.contains('--is-active') ) {
                menu.classList.remove('--is-active');
                setTimeout(function(){
                   
                    if(banner !== null){
                        menu.style.top = '3.4em';
                        banner.style.display = 'flex';
                    }else{
                        menu.style.background = 'transparent';
                    }
                    
                    enableScroll()
                }, 300);
                
                links.removeAttribute('aria-expanded');
            } else {
                menu.classList.add('--is-active');
                
                setTimeout(function(){
                    menu.classList.add('--is-active');
                }, 300);
                
                links.setAttribute('aria-expanded', 'true');
            }
        });
    };


    menuToggle();
};

export { menuTransparent };
