/**
 * Video Cards
**/


function videoCards () {
    const cards = document.querySelectorAll('.c-card-video');

    if (cards) {
        cards.forEach(card => {
            const video = card.querySelector('video');

            card.addEventListener('mouseover', () => {
                video.play();
            });

            card.addEventListener('mouseleave', () => {
                video.pause();
            });
        });
    }
};

export { videoCards };
