//
// Countdown
// ================================================================================


function countdown() {

    if (document.querySelector('.c-countdown')) {
        const releaseDate = document.querySelector('.c-countdown').getAttribute('data-release');
        const contDays = document.querySelector('.c-countdown__days .c-countdown__value');
        const contHours = document.querySelector('.c-countdown__hours .c-countdown__value');
        const contMins = document.querySelector('.c-countdown__minutes .c-countdown__value');
        const contSecs = document.querySelector('.c-countdown__seconds .c-countdown__value');

        // Set the date we're counting down to
        const countDownDate = new Date(releaseDate);

        // Update the count down every 1 second
        let theInterval = setInterval( () => {

            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Update the containers
            contDays.innerHTML = days;
            contHours.innerHTML = hours;
            contMins.innerHTML = minutes;
            contSecs.innerHTML = seconds;

            // Display the result in the element with id="demo"
            // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
            // + minutes + "m " + seconds + "s ";

            // If the count down is finished...
            if (distance < 0) {
                clearInterval(theInterval);
                console.log('EXPIRED');
            }
        }, 1000);

    }

}

export { countdown };
